import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/kunde', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/kunde/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerTreatments(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/behandlung', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/kunde', customerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/kunde/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/kunde/${customer.id}`, customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    signAgain(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/kunde/sign-again/${customer.id}`, customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTreatment(ctx, treatmentData) {
      return new Promise((resolve, reject) => {
        if (treatmentData.rabatt_percent == "") treatmentData.rabatt_percent = null;
        if (treatmentData.rabatt_money == "") treatmentData.rabatt_money = null;

        axios
          .post('/behandlung', treatmentData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTreatment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/behandlung/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTreatment(ctx, treatment) {
      return new Promise((resolve, reject) => {
        if (treatment.rabatt_percent == "") treatment.rabatt_percent = null;
        if (treatment.rabatt_money == "") treatment.rabatt_money = null;

        axios
          .put(`/behandlung/${treatment.id}`, treatment)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAddresses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/addresses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}